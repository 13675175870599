.solCard-SolutionAndReasoningWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-top: 30px;
  justify-content: space-between;
}
  
.solCard-qguidecard {
  background-color: white;
  border-radius: 22px;
  border: 2.582px solid rgba(0, 0, 0, 0.09);

  width: 571px;
  height: 100%;
  padding: 15px;
}

.solCard-question-during-analysis {
  color: #2c6ab1;
  font-family: Cardo;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 137.4%; /* 27.48px */
  opacity: 0.9;
  word-wrap: break-word;
  overflow-wrap: break-word;
  text-align: left;
  white-space: pre-wrap;
}

.solCard-qSerialNum {
  margin-right: 10px;
  font-style: italic;
  font-size: medium;
  color: #2C6AB1;
  font-weight: 400;
  opacity: 0.7;
}

.solCard-questionWithSerialNumber {
  display: flex;
  flex-direction: row;
  color: #2c6ab1;
  font-family: Cardo;
  font-size: 20px;
  font-style: normal;
  line-height: 137.4%; /* 27.48px */
  opacity: 0.9;
  word-wrap: "break-word";
  overflow-wrap: "break-word";
  margin-top: 10px;
}

.sol-options {
  display: flex;
  flex-direction: row;
  border-radius: 11px;
  border: 2.582px solid rgba(0, 0, 0, 0.09);
  background: #d9d9d9 0.1;
  color: #2c6ab1;
  font-family: Cardo;
  font-size: 19px;
  text-align: left;
  justify-content: left;
  padding: 12px;
  max-height: fit-content;
  margin-top: 20px;
  max-width: fit-content;
  width: 100%;
  word-wrap: "break-word";
  overflow-wrap: "break-word";
}
  
@media only screen and (max-width: 860px) {

  .solCard-SolutionAndReasoningWrapper {
    display: none !important;
  }

  .solCard-qguidecard {
    order: 3;
    width: 100%;
    margin: 0;
    align-self: center;
  }

}
  